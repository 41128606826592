body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-family: "Poppins", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.style {
  min-height: 4rem;
}
@media screen and (max-width: 1024px) {
.container-div{
  padding: 0rem 7rem !important;
}
}
.container-div{
  padding: 0rem 1rem 0rem 1rem !important;
}
.nav1 {
  cursor: pointer;
}
.header-menu-padding{
  padding: 0.4rem;
}
.offcanvas {
  width: 19rem !important;
  background-color: #ffffff !important;
  border-right: none !important;
  /* box-shadow: 0px 1px 5px 5px rgba(66, 66, 66, 0.5) !important; */
}
.offcanvas-end{
  border-left: none !important;
}
@media only screen and (min-width: 780px) {
 .offcanvas-end{
  width: 25rem !important;
 }
}
/* .offcanvas:hover {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5) !important;
} */
.side-nav-header-width{
  padding: 1rem;
  background-color:#3949ab ;
  border-bottom: 1px solid rgb(226, 221, 221)
}
.lihover:hover{
  background-color: #e8eaf6 !important;
  /* border-radius: 20px; */
}
.lihovers:hover{
  background-color: #e8eaf6 !important;
  border-radius: 8px;
}
.close-btn{
  position: absolute;
  right: 0.5rem;
}
.container-fluidc {
  padding: 0 !important;
  margin: 0 !important;
}
.nav-bar-bg,.bg-color{
  /* background-color: #000000; */
  background-color: #3949ab;
}

@media only screen and (max-width: 600px) {
  .title {
 font-size: 15px !important;
  }
  .price{
    font-size: 12px !important;
  }
}
.price{
  font-family: "Roboto", sans-serif;
  color: #9e9e9e;
}
.roboto{
  font-family: "Roboto", sans-serif;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.hide-header {
  top: -100px; /* Move header up to hide it */
}

.show-header {
  top: 0; /* Header stays in place */
}
.pointer{
  cursor: pointer;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  animation: slide-down ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.removing {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.hoverborder:hover{
  border: none !important;
}

.custom-select {
  border: none !important; /* Removes border */
  outline: none !important; /* Removes focus outline */
  box-shadow: none; /* Removes any default box-shadow */
  appearance: none; /* Removes default browser styling */
  color: rgb(0, 0, 0) ;
  width: auto;
}
.custom-select:focus {
  outline: none !important; /* Removes outline on focus */
  box-shadow: none !important; /* Removes focus box-shadow */
}

 .addbtnhover:hover{
  background-color: #9fa8da !important; 
 }
 .popaddbtnhover:hover{
  background-color: #5c6bc0 !important;
 }
 .cancelbtnhover:hover{
  background-color: #c5cae9 !important;
 }
 input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}
.side-body{
  height: 1200px;
}
.shop-details {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.shop-name {
  font-size: 14px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shop-code {
  font-size: 14px;
}

/* Profile Icon */
.profile-icon {
  cursor: pointer;
}

/* Popup Menu */
.popup-menu {
  top: 100%;
  right: 0;
  width: 200px;
  z-index: 1000;
  padding: 8px 0;
}

.popup-item {
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.3s;
}

.popup-item:hover {
  background-color: #f1f1f1;
}

.tuncate-name{
  width: 100px;
    white-space:nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
